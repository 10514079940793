<template>
  <footer class="footer">
    <div class="app-name">心晴</div>
    <a class="website-url" target="_blank" href="http://xinqing.life">xinqing.life</a>
    <div class="visitors-count">累计{{ this.visitor_count }}位朋友访问心晴</div>

    <div class="group-2" @click="goToDonatePage">
      <img class="icon-piece" src="../images/like-selected.svg" />
      <span class="sponsor">赞赏心晴</span>
    </div>
  </footer>
</template>
  
<script>
import Swal from 'sweetalert2'
import axios from 'axios';
export default {
  data() {
    return {
      visitor_count: 0,
    };
  },
  async created() {
    const response = await axios.get('/api/v1/systeminfo/base');
    console.log(response);
    this.visitor_count = response.data.visitor_count;
  },
  methods: {
    goToDonatePage() {
      Swal.fire({
        imageUrl: require("@/images/sponsor.webp"),
        titleText: "赞赏心晴",
        text: "1元起，支持心晴持续运营～",
        confirmButtonText: "已经赞赏啦！",
        confirmButtonColor: "#fa607d",
        imageHeight: 300,
        imageAlt: "A tall image"
      });
    },
  },
};
</script>
  
<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
  /* 黑体 */
  color: #808080;
  /* 灰色 */

  margin-bottom: 20px;
}

.app-name,
.website-url,
.visitors-count {
  margin: 0 10px;
  color: #808080;
  font-weight: bold;
  /* 加粗 */
}

.website-url:visited {
  color: #808080;
  /* 灰色 */
}

.website-url:active {
  color: #808080;
  /* 灰色 */
}

.donate-button {
  padding: 10px 20px;
  background-color: #808080;
  /* 灰色 */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  /* 加粗 */
}

.group-2 {
  background-color: rgba(250, 96, 125, 1);
  position: relative;
  display: flex;
  justify-content: center;
  /* right: 15px; */
  top: 10px;
  width: 80px;
  padding: 7px 9px;
  border-radius: 15px;
  margin: 0 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.icon-piece {
  width: 13px;
  height: 11px;
  align-self: center;
  margin-bottom: 1px;
}

.sponsor {
  margin-left: 4px;
  /* font-weight: 700; */
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 16px;
  align-self: center;
  font-weight: bold;
  font-family: sans-serif;
  white-space: nowrap;
}
</style>