<template>
  <div class="page">
    <div class="container">

      <div class="body">
        <div class="icon-down-wrapper">
          <img class="icon-down" src="../images/icon-down.png" />
        </div>
        <div class="group-1">
          <span class="title">心晴</span>
          <span class="xinqinglife">xinqing.life</span>
        </div>
      </div>
      <div class="search-row">
        <img class="icon-down-1" src="../images/icon-down.png" />
        <!-- <img
            class="dot-1"
            src="../images/img_5.png"
          /> -->
        <!-- <span class="search">搜索心晴…</span> -->
        <input type="text" class="search" v-model="search" placeholder="搜索心晴…" />
      </div>
      <ul class="ulli center-ulli">
        <li v-for="item in filteredItems" :key="item.display_id">
          <div class="main">
            <img class="mainpage-pic cardshadow" :src="item.image" />
            <div class="group-2" v-on:click="submit_mood(item.id)">
              <img class="icon-piece" :src="item.icon" />
              <span class="num">{{ item.num }}</span>
            </div>
            <div class="group-3">
              <span class="info">{{ item.info }}</span>
              <span class="label">{{ item.label }}</span>
            </div>
          </div>
        </li>
      </ul>



    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
axios.defaults.withCredentials = true;
import { API_BASE_URL } from '@/const.js'; // 路径可能需要根据你的项目结构进行调整

export default {
  setup() {

  },
  mounted() {
    document.title = '心晴 | 15分钟换个心情';
  },
  data() {
    return {
      search: '',
      items: [],

    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => {
        return item.info.includes(this.search);
      });
    }
  },
  methods: {
    async submit_mood(moodid) {
      // 提交心情
      console.log("submit_mood: " + moodid);
      try {
        const response = await axios.post(`${API_BASE_URL}/api/v1/mood/submit`, { id_mood: moodid, withCredentials: true });
        console.log('请求成功:', response);
        // 找到对应的卡片
        const item = this.items.find(item => item.id === response.data.id_mood);
        if (item) {
          // 更新计数
          item.num = response.data.return_count;
        }
        // 跳转到 Result 路由
        this.$router.push('/Result');
      } catch (error) {
        console.error('请求失败:', error);

        Swal.fire({
          icon: "info",
          title: "刚刚已经发表心情啦！",
          text: "请等待" + error.response.data.message + "秒再发表心情哦！",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "查看心情",
          // showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "正在跳转...",
              icon: "success",
              showConfirmButton: false,
            });
            setTimeout(() => {
              this.$router.push('/result');
              Swal.close();
            }, 1000);
          }
        });
      }
    },
  },
  async created() {
    const response = await axios.get(API_BASE_URL + '/api/v1/mood/all', { withCredentials: true });
    this.items = response.data.moods.map(mood => ({
      id: mood.id,
      image: mood.imgurl,
      icon: mood.selected ? require('../images/like-selected.svg') : require('../images/like.svg'), // 你可能需要从服务器获取这个值
      num: mood.number,
      info: mood.title,
      label: mood.content
    }));
  }

};

</script>
  

<style src="../../global.css" />
  <style src="./MainPage.css" />

<style scoped>
.ulli {
  margin: 0;
  padding: 0;
  list-style: none;
}

.center-ulli {
  background-color: rgba(255, 255, 255, 1);
  /* display: flex; */
  justify-content: flex-start;
  align-self: center;
  width: 335px;

}

.page .search-row {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 335px;
  box-sizing: border-box;
  padding: 14px 12px 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 27.5px;
}

.search {
  margin: 0 0 1px 9px;
  font-size: 18px;
  color: rgba(124, 134, 152, 1);
  line-height: 20px;
  font-family: Poppins-Regular;
  align-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 200px; */
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  padding: 0;
  box-sizing: border-box;
  /* 使宽度包括内边距和边框，而不仅仅是内容 */
}

.cardshadow {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
</style>
  ;
  