import { createRouter, createWebHistory } from "vue-router";
// import App from './App.vue';
import MainPage from './components/MainPage.vue';
import ResultCard from './components/ResultCard.vue';
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'MainPage',
            component: MainPage,
        },
        {
            path: '/result',
            name: 'Result',
            component: ResultCard,
        }
    ],
    // 每次切换路由时，页面滚动到顶部
    scrollBehavior () {
        return { top: 0 }
    }
    
})
export default router;
