<!-- import MainPage from './views/MainPage.vue' -->
<template>
  <div class="app-wrapper">

      <router-view></router-view>
    
    <!-- <MainPage /> -->
    <!-- <ResultCard /> -->
    <Footer />
  </div>
</template>
<script>



export default {
  components: {

  },
  data() {
    return {
      constants: {}
    }
  },
  methods: {

  },

}
</script>

;

<style scoped>
.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f8f9fa;
}
</style>
