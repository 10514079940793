import router from './router.js';
import { createApp } from 'vue';
import App from './App';
import ResultCard from './components/ResultCard.vue';
import MainPage from './components/MainPage.vue';
import Footer from './components/Footer.vue';

import axios from 'axios';
import { API_BASE_URL } from './const.js';
// 设置axios默认带上cookie
axios.defaults.withCredentials=true;
axios.defaults.baseURL = API_BASE_URL;


const app = createApp(App);
app.component("ResultCard", ResultCard);
app.component("Footer", Footer);
app.component("MainPage", MainPage);
app.use(router); // Add this line to use the router
app.mount('#app');
