<template>
    <div class="page">
        <div class="wrapper-1">
            <div class="header">
                <router-link class="return-to-index-button" to="/">&lt;</router-link>

            </div>
            <div class="body">
                <span class="tag">{{ this.title }}</span>
                <span class="info">今天，全球{{ this.count_today }}人与你共鸣</span>
                <!-- <hr class="separator" /> -->
                <span class="desc">{{ this.remain_time }}后，你可以再次发表你的心情</span>
            </div>
        </div>
        <div class="item-long-wrapper">
            <img class="item-long" :src="this.imgurl" />
            <div class=" msg-card">
                <span class="title">{{ this.content_title }}</span>
                <span class="article">{{ this.content }}</span>
            </div>
            <div class=" share-to-friends" @click="share_to_friends_fn"><span class="share" >分享给好友</span></div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
axios.defaults.withCredentials = true;
import { API_BASE_URL } from '@/const.js';

export default {
    data() {
        return {
            id: null,
            title: '',
            content_title: '',
            content: '',
            imgurl: '',
            remain_time: '分钟',
            count_today: null,
        }
    },
    methods: {
        async split_content_title(content) {
            // 删除 "标题" 这两个字
            content = content.replace("标题：", "");
            // 删除段首的所有空格及换行、中文引号
            content = content.trim();

            // 删除开头的中文引号
            content = content.replace(/^[“"]/, "");
            // 把"\\n"替换成"\n"
            content = content.replace(/\\n/g, "\n");

            // 获取第一句或者前20个字作为标题
            let content_title = content.split(/[。.!！？?\n]/)[0];
            if (content_title.length != content.length) {
                content_title += content[content_title.length];
            }
            if (content_title.length > 20) {
                content_title = content_title.slice(0, 20) + '...';
            }
            let res_content = content.substring(content_title.length);
            // 返回标题以及内容
            return [content_title, res_content];
        },
        async share_to_friends_fn() {
            Swal.fire({
                imageUrl: require("@/images/share.png"),
                titleText: "与好友分享心晴",
                text: "长按保存图片即可分享",
                confirmButtonText: "确认",
                confirmButtonColor: "#5680FA",
                imageHeight: 400,
                imageAlt: "A tall image"
            });
        }
    },
    async created() {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/v1/mood/current`, { withCredentials: true });
            console.log('请求成功:', response);
            let rr = await this.split_content_title(response.data.content);
            let content_title = rr[0];
            // 设置网页标题
            document.title = '心晴 xinqing.life | ' + content_title;
            let content = rr[1];
            console.log("title:" + content_title + "\ncontent:" + content);
            this.id = response.data.id;
            this.title = response.data.title;
            this.content_title = content_title;
            this.content = content;
            this.imgurl = response.data.imgurl;
            this.count_today = response.data.count_today;

            if (response.data.remain_seconds > 60) {
                this.remain_time = Math.floor(response.data.remain_seconds / 60) + '分钟';
            } else {
                if (response.data.remain_seconds <= 0) {
                    this.remain_time = '0秒';
                } else {
                    this.remain_time = response.data.remain_seconds + '秒';
                }
            }
        } catch (error) {

            console.error('请求失败:', error);
            if (error.response.data.status_code == 50000) {
                // 系统繁忙
                Swal.fire({
                    icon: "error",
                    title: "系统繁忙",
                    text: "请稍后再试噢～",
                    confirmButtonColor: "#3085d6",
                    // confirmButtonText: "查看心情",
                    showConfirmButton: false,
                    // showCancelButton: true,
                });
                setTimeout(() => {
                    Swal.close();
                    this.$router.push('/');
                }, 2000);
            } else if (error.response.data.status_code == 40403) {
                // 系统繁忙
                Swal.fire({
                    icon: "info",
                    title: "还没有提交心情噢～",
                    text: "快去选择一个心情吧～",
                    confirmButtonColor: "#3085d6",
                    // confirmButtonText: "查看心情",
                    showConfirmButton: false,
                    // showCancelButton: true,
                });
                setTimeout(() => {
                    Swal.close();
                    this.$router.push('/');
                }, 2000);
            }
        }
    }
}


</script>
  

<style scoped>
.return-to-index-button {
    background-color: rgb(255, 238, 238);
    /* color: #808080; */
    position: relative;

    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    left: 10%;
    height: 30px;
    width: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 20px;
    transform: translateX(-50%);
    text-decoration: none;
    /* 添加这一行 */

    /* padding: 20.5px 12px 22.5px; */
    border-radius: 5px;
    opacity: 1;

    box-shadow: 0px 5px 10px rgba(180, 135, 161, 0.25);
}

.return-to-index-button:visited {
    color: #808080;
    /* 灰色 */
}

.return-to-index-button:active {
    color: #808080;
    /* 灰色 */
}

.page {
    background-color: rgba(250, 250, 250, 1);
    /* background-image: url(../images/bg.png); */
    background-repeat: no-repeat;
    background-position: 20px 70px;
    background-size: 20px 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 16px 0px 91px;
    font-family: Poppins-Bold;
}

.page .header {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    width: 351px;
    height: 20px;
}

.page .wrapper-1 {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    justify-content: flex-start;
    align-self: center;
    width: 351px;
}

.page .icon-down-1 {
    width: 8px;
    height: 14px;
    margin-left: 8px;
    align-self: flex-start;
}

.page .tag {
    color: rgba(32, 49, 82, 1);
    font-size: 24px;
    line-height: 34px;
    align-self: center;
    width: 100%;
    max-width: 100%;
    margin: 0 0 0 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.page .item-long-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 39px;
    justify-content: center;
    align-self: center;
    position: relative;
    /* overflow: visible; */
    width: 345px;
}

.page .item-long {
    width: 295px;
    height: 479px;
    align-self: center;
    border-radius: 20px;
    object-fit: cover;
}

.page .group {
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    bottom: -34px;
    width: 335px;
    transform: translateX(-50%);
    padding: 28px 12px 108px;
    border-radius: 10px;
}



.page .share {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 16px;
    margin-right: 2px;
    align-self: flex-start;
    max-width: 267px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.page .msg-card {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    bottom: -34px;
    margin-top: -300px;
    margin-left: 0;
    width: 335px;
    min-height: 160px;
    transform: translateX(-50%);
    padding: 28px 12px 108px;
    border-radius: 10px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.page .share-to-friends {
    background-color: rgba(86, 128, 250, 1);
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    left: 50%;
    bottom: -61px;
    width: 295px;
    transform: translateX(-50%);
    padding: 20.5px 12px 22.5px;
    border-radius: 27.5px;
    opacity: 1;

    background: rgba(86, 128, 250, 1);
    box-shadow: 0px 20px 30px rgba(86, 128, 250, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.1);

}

.separator {
    left: 0px;
    top: 30px;
    width: 186.61px;
    height: 0.49px;
    opacity: 1;
    background: rgba(225, 225, 225, 1);
    display: flex;
    border: none;
    /* 移除默认的边框 */
    margin: 20px auto;
    /* 增加上下外边距，使其居中 */
}

.page .body {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    align-self: center;
    width: 351px;
    align-items: center;

}


.page .info {
    color: rgba(124, 134, 152, 1);
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 18px;
    align-self: flex-start;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin: 6px 0 0 0px;
}


.page .desc {
    color: rgba(124, 134, 152, 1);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: auto;
    align-self: flex-start;
    text-align: center;
    /* max-width: 600px; */
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px 0 0 0px;
}

.page .title {
    color: rgba(32, 49, 82, 1);
    font-size: 14px;
    line-height: 26px;
    align-self: center;
    max-width: 311px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    margin: 0 0 0 0;
    /* 加粗 */
    font-weight: bold;
    text-overflow: ellipsis;
}

.page .article {
    color: rgba(32, 49, 82, 1);
    font-family: Poppins-Light;
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
    text-align: center;
    align-self: center;
    margin-right: 1px;
    margin-top: 20px;
    width: 286px;
    min-height: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: normal; */
    white-space: pre-wrap;
}
</style>
  ;
  